body {
  font-family: Helvetica, 'Nunito Sans', sans-serif;
  letter-spacing:1px;  
}

#page {
  overflow:hidden;
}

$primary-color: #5D4B3F;
$secondary-color: #906D51;
$dark-color: #4F4C48;
$light-brown: #E1D7C8;

a:hover {
  color: #171F30;
}

.en .nav-en, .sc .nav-sc {
  display: none;
}

h1, h2, h3, h4 {
  font-family: Helvetica, 'Nunito Sans', sans-serif;
  color: $secondary-color;

}

h1 {
  font-size: 22px;
  line-height:1.2;
  @include breakpoint(large) {
    font-size: 3rem;
  }
}

h2 {
  font-size: 20px;  
  line-height:1.3;

  @include breakpoint(large) {
    font-size: 35px; 
  }
}

h3 {
  font-size: 18px;
  line-height:1.3;

  @include breakpoint(large) {
    font-size: 30px; 
  }
}

h4 {
  font-size: 16px;
  @include breakpoint(large) {
    font-size: 25px; 
  }
}

p {  
  font-size: 16px;
  color: $secondary-color;  
  line-height: 1.6;
  font-weight:300;

  @include breakpoint(large) {
    font-size: 20px;
  }

  &:last-child {
    margin-bottom:0;
  }
}

p.large {
  font-size: 23px;
  line-height: 1.3;
}

.small {
  font-size: 8px;
}

a {
  font-size: 16px;
  @include breakpoint(large) {
    font-size: 20px;
  }
}

.thin {
  font-weight:300!important;
}

.bold {
  font-weight: 600;
}

.bg-primary {  
  background: $primary-color;
}

.bg-secondary {
  background: $secondary-color;
}


.bg-light-grey {
  background: rgba(50,50,50,0.1);
}

.bg-light-blue {
  background: #8396a0;
}

.bg-light-brown {
  background: $light-brown;
}


.bg-white {
  background: #fff;
}

.primary {
  color: $primary-color!important;
}

.secondary {
  color: $secondary-color!important;
}

.link {
  font-size: 12px;  
  margin-top: 18px;
  border-bottom: 1px solid $primary-color;
  padding-bottom: 2px;
}

.right {
  float:right;
}

.flex {
  display:flex;  
}

.equal-height {
  display:flex;
  width: 100%;
  flex-wrap: wrap;

  & .columns {
    flex: none;

    @include breakpoint(large) {
      flex: 1;
    }
  }

  .large-8 {
    flex: none;
    @include breakpoint(large) {
      flex: 0 0 66.66667%;
    }
  }

  .large-4 {
    flex: none;
    @include breakpoint(large) {
      flex: 0 0 33.33333%;
    }
  }

  @include breakpoint(large) {
    display:flex;
  }

}

.overflow-visible {
  overflow:visible!important;
}

.padding-side {
  padding: 7% 7%!important;

  @include breakpoint(large) {
    padding: 0 10%!important;
  }

}

.padding-top {
  padding: 6% 0%!important;
}

.row {
  overflow: hidden;
}

.spacing20 {
  height: 20px;
}

.spacing40 {
  height: 20px;
  @include breakpoint(large) {
    height: 40px;
  }
}

.spacing60 {
  height: 30px;
  @include breakpoint(large) {
    height: 60px;
  }
}

.spacing80 {
  height: 20px;
  @include breakpoint(large) {
    height: 80px;
  }
}

.spacing100 {
  height: 20px;
  @include breakpoint(large) {
    height: 100px;
  }
}

nav {
  // position: absolute;
  z-index: 4;
  width: 100%; 
  text-align: left;  
  background-color:white;  
  padding: 10px 0;

  @include breakpoint(large) {    
    padding: 30px 0 50px;
  }

  .row {
    max-width:85em;
    overflow:hidden;

    @include breakpoint(large) {    
      overflow:visible;
    }
  }

  .logo {
    width: 120px;
    display: inline-block;
    // margin-right: 0; 
    // margin-bottom: 20px;
    background:white;    
    position:static;    

    @include breakpoint(large) {
      width: 250px;
      position:absolute;
      top: 0;
      left: 0;
    }   

    // @media only screen and (min-width: 1175px) {
    //   margin-right: 120px;
    //   margin-bottom: 0px;
    // }
  }


  .nav-item {
    display: inline-block;
    z-index: 999;    
    position: fixed;
    width: 100%;
    height: 100%;
    top: -200vh;
    left: 0;
    transition: 0.5s all ease;
    background-color: $primary-color;

    &.active {
      top: 0;

      a {
        color: white;
      }
    }

    @include breakpoint(large) {
      display: inline-block;
      position: static;
      float:right;
      width: auto;
      height: auto;
      top: 0;
      left: 0;
      transition: 0.5s all ease;
      background-color: transparent;
    }

    a {
      font-size: 20px;
      margin: 8vh 0 8vh;
      display: block;
      text-align: center;
      line-height: 1;      
      color: $primary-color;
      letter-spacing:1px;
      font-weight:300;
      font-family: 'Montserrat', sans-serif;

      @include breakpoint(large) {
        margin: 0 0 0 70px;
        display:inline-block;
        text-align: left;
        font-size: 14px;
      }
    }
  }


  a {        
    font-size: 16px;
    // color: #FFFFFF;
    text-align: left;
    text-transform: uppercase;
  }
}

.fade-up {
  transform: translateY(15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-left {
  transform: translateX(-15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-right {
  transform: translateX(15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-delay {
  transition-delay: 0.2s;
}

.fade-delay2 {
  transition-delay: 0.3s;
}

.fade-delay3{
  transition-delay: 0.4s;
}
  
$tcon-size: 30px !default;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$primary-color: #fff !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
) !default;
.tcon {
  @include breakpoint(large) {
    display: none;
  }
  position: absolute;
  top: 30px;
  transform: translateY(-50%);
  right: 25px;    
  z-index: 99999;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  > * {
    display: block;
  }

  &:hover,
  &:focus {
    outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: ($tcon-size / 15);
  width: $tcon-size;
  border-radius: $tcon-menu-radius;
  transition: $tcon-transition;
  background: $primary-color;
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;

  &::before,
  &::after {
    @include tcon-menu-lines;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 14) center;
    width: 100%;
  }

  &::before {
    top: ($tcon-size / 4);
  }

  &::after {
    top: -($tcon-size / 4);
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.8, .8, .8);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}


.hero {
  height: 60vh;
  width: 100%;
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  position:relative;  

  @include breakpoint(large) {
    height: 80vh;        
  }

  .row {
    z-index: 1;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  h1 {    
    font-size: 30px;    
    font-weight: 400;
    letter-spacing: 0.5px;    
    width: 90%;
    margin: 0 auto;
    @include breakpoint(large) {
      font-size: 50px;
      width: 100%;
    }
  }
  p {
    
    font-size: 16px;    
    letter-spacing: 0.58px;

  }
}


.contact {
  .padding-intro {
    padding: 30px 20px 10px;

    @include breakpoint(large) {
      padding: 80px 100px 60px;
      margin-top: 0;
    }
  }
}

.home .hero {
  background-image:url(/assets/images/home/hero.jpg);
  background-position: center;
}

.about .hero {
  background-image:url(/assets/images/about/hero.jpg);
  background-position: top center;
}

.contact .hero {
  background-image:url(/assets/images/contact/hero.jpg);
}

.homeowner .hero {
  background-image:url(/assets/images/homeowner/hero.jpg);
  background-position: top center;
}

.projects .hero {
  background-image:url(/assets/images/projects/hero.jpg);
}

.privacy .hero {
  background-image:url(/assets/images/privacy.jpg);
}


.bg-opacity {
  background: rgba(38,49,80,0.8);
}

.large-text {
  font-size: 40px;
  font-weight: 500;
  opacity: 0.1;
  color: $primary-color;
  line-height: 1;

  @include breakpoint(large) {
    font-size: 170px;
  }

}


.show-on-medium {
  display: none;
  @include breakpoint(medium) {
    display: block
  }
}

.v-align-center {
  display: flex;
  align-items: center;  
  flex-wrap: wrap;


  @include breakpoint(large) {
    display: flex;
  }
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.white {
  color: white!important;
}

.dark {
  color: $dark-color!important;
}

hr {
  border-bottom: 1px solid $primary-color;  
}

.relative {
  position: relative;
}

.button {
  background-color: transparent;
  border: 1px solid $secondary-color;
  
  padding: 13px 35px;
  color: $secondary-color;
  transition: 0.5s all ease;

  &:hover {
    background-color:$secondary-color!important;
    color: white!important;
  }



  &.filled {
    background-color: $primary-color;
    color: white;

    &:hover {
      border: 1px solid $primary-color;
    }

    &.white {
      background-color:white!important;
      color: $primary-color!important;
    }

    &.dark {
      background-color: $secondary-color;
      color: white;
    }

  }
  &.white {
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }

  &.dark {
    color: $dark-color;
    border: 1px solid $dark-color;
  }

  &.inline {
    display: inline-block;
    margin-right: 10px;
  }

}

.home {
  .transca-icon {
    position:absolute;    
    width: 100%;
    max-width: 60px;
    top: 7%;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    z-index:1;
    transition: 1.5s all ease;
    transform: translateY(40px);
    opacity:0;
    &.active {
      transform: translateY(0);
      opacity:1;
    }

    @include breakpoint(large) {
      max-width: 227px;
    }
  }

  .intro {
    transform: translateY(15px);
    opacity: 0;
    transition: 0.9s all ease;
  }
  .sky {
    opacity:0;
    transition: 0.9s all ease;
  }
  .mountains {
    opacity:0;
    transition: 0.9s all ease;
  }


  .home-hero {
    position:relative;
    overflow:hidden;
    height: 40vh;
    background:url('/assets/images/home/hero.jpg') top center no-repeat;
    background-size: cover;    
    &.active {
      .transca-icon,.sky,.mountains,.intro {
        transform: translateY(0);
        opacity:1;
      }
    }
    .animations {
      display:none;
    }

    @include breakpoint(large) {
      .animations {
        display:block;
      }
      height: auto;
    }
    

    h1 {
      position: absolute;
      top: 50%;
      text-align: center;   
      width: 100%;
      z-index:3;  
      transition-delay:1.1s; 
    }  
  }
  
  .mountains {
    position:absolute;
    display:block;
    top: 0;
    left: 0;
    width:100%;
    z-index:2;
  }


  .home-padding {
    padding: 20px;
    @include breakpoint(large) {
      padding:60px 60px 60px 170px;
    }
  }

  
}

.columns.columns-padding {
  padding: 20px;
  @include breakpoint(large) {
    padding:60px;
  }
}

.columns.columns-padding-right{
  padding: 20px;
  @include breakpoint(large) {
    padding:60px 15% 60px 60px;
  }
}


.bg-building1 {
  background: url('/assets/images/home/1.jpg') top center no-repeat;
  background-size: cover;
  height: 100vh;

  @include breakpoint(large) {
    height: auto;
  }
}

.icon {
  display: block;
  width: 40px;
  margin: 0 auto 20px;
  z-index: 2;
}


footer {  
  
  p,a {
    font-size: 16px;
  }

  a {
    color: white;        
    display: block;        

    &:hover {
      color: white;    
    }
  }

  .logo {
    width: 100%;
    max-width:300px;
    display: inline-block;
    margin-bottom: 50px;    
    padding-right: 30px;
  }
  
  .footer-contact {
    background-color: $primary-color;
    padding: 50px 0;
  }  
}

.incomplete-row {
  .columns {
    float: none!important;
    display: inline-block;
  }
}

.contact {
  .map {    
    z-index: 2;
    display: block;
    max-width: 75rem;
    margin: -50px auto 0;

    img {
      width: 100%;
      display: block;
    }
  }

  &-info {
    
    h4 {
      margin-bottom: 0;
    }
    .columns {
      margin-bottom: 30px;
    }
  }
}


.border-right-dark {
  border-right: 1px solid $secondary-color;
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
  .row::before, .row::after {
    display: none!important; 
  }
}

ul {
  li {
    text-align: left;
    
    font-size: 16px;
    color: #1C3E51;
    letter-spacing: 0.58px;
    line-height: 1.7;
  }
}

hr.white {
  border-bottom: 1px solid white;
}

.disclaimer, .privacy {
  .hero {
    height: 50vh;
  }
}

.negative-margin {  
  margin-top: 0;  
  z-index:1;
  position:relative;

  @include breakpoint(large) {
    margin-top: -60px;    
  }
}
.z2 {
  z-index:2;
  position:relative;
}
.shadow {
  box-shadow: 0px 0px 12px -1px rgba(0,0,0,0.88);
}
.social {
  a {
    display:inline-block;
    margin-right:10px;
    width: 30px;  

    @include breakpoint(large) {
      margin-left:10px;
    }
  }
}

.column, .columns {
  padding-left: 20px;
  padding-right: 20px;
  @include breakpoint(large) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.mobile-order-1 {
  order: 1;
  @include breakpoint(large) {
    order: initial;
  }
}

.mobile-order-2 {
  order: 2;
  @include breakpoint(large) {
    order: initial;
  }
}

.project-wrap {
  overflow:visible;
  position:relative;
}

.project-description {
  position:static;    
  width: 100%;
  padding: 60px;   

  @include breakpoint(large) {
    position:absolute;
    top:-10%;
    left: -16%;
    height: 100%;
    width: 100%;
    padding: 15% 40px 20px 30%; 
    z-index:-1;
  }
}

.project-description-even {
  position:static;    
  width: 100%;
  padding: 60px;   
  @include breakpoint(large) {
    position:absolute;
    top: 10%;
    left: 20%;
    height: 100%;
    width: 100%;
    padding: 15% 40px 20px 80px;
    z-index: -1;
  }
}


.mobile-hide {
  display:none;
  @include breakpoint(large) {
    display:block;
  }
}

.privacy .content {
  p, li, a {
    font-size: 13px;
    color: $dark-color;
  }
}

.contact {
  input, select, textarea {
    background-color: transparent;
    margin:0 0 15px;
    border: 0;
    box-shadow: none;
    outline: 0;
    border-style:solid;
    border-width:0;
    border-bottom: 1px solid $dark-color;    
    font-size: 14px;
    color: $dark-color;
  }

  textarea {
    min-height: auto;
  }

  .click {
    font-size: 12px;
    float:right;
    width: 86%;

    @include breakpoint(large) {
      width: 96%;      
    }

    a {
      font-size: 12px;
    }
  }

  .button {
    margin-top: 40px;
    border: 1px solid $dark-color;  
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark-color!important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $dark-color!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $dark-color!important;
}

// .mobile-padding {
//   width: 80%;
//   margin: -20px auto;
//   padding: 30px;
//   z-index:2;
//   position:relative;

//   @include breakpoint(large) {
//     width: auto;
//     margin: initial;

//   }
// }

.inline-block, .inline-block a {
  display:inline-block;
}


nav .relative {
  overflow:visible;
  z-index:10;
}